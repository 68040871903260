import { CButton } from '@coreui/react-pro';
import svgImage9 from 'assets/images/illustrations/Monitzion.svg';
import React, { useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Card } from 'reactstrap';
import { SaveLoader } from 'src/common/SaveLoader';
import { getIsLoggedIn, login } from 'src/services/authService';
import Cookies from 'universal-cookie';
import Microsoftlogo from '../../src/assets/images/microsoft.png'
import monitzionLogo from '../../src/assets/images/Monitzion_logo_big_145hpx.png'

const Invitation = () => {

  const [isProcessingInvitation, setIsProcessingInvitation] = useState(false);
  const cookies = new Cookies();
  let path = window.location.pathname;
  let splitdata = path.split('/');
  const invitationKey = splitdata[splitdata.length - 1];

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    localStorage.setItem('redirectPath', searchParams.get("redirectPath"));
    localStorage.setItem('loginPathCompanyId', searchParams.get("ClientCompanyId"));
  }, [])

  const onMicrosoftLoginClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsProcessingInvitation(true);
    localStorage.setItem('invitationKey', invitationKey);
    login();
  };

  //Google Authentication Process
  const responseGoogleError = (response) => {
    console.log(response);
  };
  const responseGoogle = (response) => {

    if (response.profileObj != null) {
      //localStorage.clear();
      localStorage.clear();
      localStorage.setItem('googletoken', response.tokenId);
      localStorage.setItem('googleUser', 'true');
      localStorage.setItem('userName', response.profileObj.name);
      localStorage.setItem('email', response.profileObj.email);
      localStorage.setItem('googletokenclient', response.tokenId);
      localStorage.setItem('loginToken', response.tokenId);
      localStorage.removeItem('loginSc');
      window.location.reload();
    }
  };


  return (
    <div style={{ paddingLeft: "100px", paddingRight: '100px' }}>
      <div style={{ textAlign: 'center', marginTop: '20%' }}>
        <span className="w-100 text-left text-md-center pb-4">
          <img alt="image Missing" className="w-40 mx-auto d-block img-fluid" width={500} src={monitzionLogo} />
          <br />
          <h1 style={{ fontWeight: 'bold' }}>
            You have been invited to Monitzion
          </h1>
          <p style={{ fontFamily: 'Helvetica', color: 'rgba(7,9,25,.5)', fontWeight: 'bold' }}>
            To complete setting up your account please login below.
          </p>
        </span>

        <Card className="d-block p-3 mx-2 mb-0 mt-2 rounded border-0"
          style={{ paddingLeft: "24px !important", paddingRight: '24px !important' }}>
          <div>
            <div className="text-muted text-center mb-3">
              <span>Sign in with</span>
            </div>
            <div className="text-center">
              <CButton
                style={{ height: '43px', width: '115px', fontSize: 14 }}
                onClick={onMicrosoftLoginClick}
                disabled={isProcessingInvitation}
              >
                <span className="btn-wrapper--icon">
                  <img alt="image Missing" width={15} style={{ marginRight: "1px" }} height={15} src={Microsoftlogo} /> Microsoft
                </span>
              </CButton>

            </div>
            <div className="m-2 text-center" >

              <GoogleLogin
                clientId={'826839568520-1a61t8o6n2c87lv3aa41ijocuvl64qql.apps.googleusercontent.com'}
                render={renderProps => (
                  <button type="button" onClick={renderProps.onClick} className='btn btn-primary'
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      padding: "0px", border: "1px solid transparent", fontSize: "14px",
                      fontWeight: "500", fontFamily: "Roboto , sans-serif",
                      width: "115px"
                    }}>
                    <div style={{
                      padding: "4px",
                      paddingLeft: "16px"
                    }}>
                      <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#000" fillRule="evenodd">
                          <path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path>
                          <path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path>
                          <path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path>
                          <path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path>
                          <path fill="none" d="M0 0h18v18H0z"></path>
                        </g>
                      </svg>
                    </div>
                    <span style={{ padding: "10px 10px 10px 0px", fontWeight: "500", marginRight: "12px" }}>Google</span>
                  </button>
                )}
                buttonText="Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogleError}
                cookiePolicy={'single_host_origin'}
                responseType="code,token"
                isSignedIn={null}
              />
            </div>
            <span className="w-100 text-left text-md-center pb-4">
              <p style={{ fontFamily: 'Helvetica', color: 'rgb(7 9 25 / 26%)', fontWeight: '600' }}>
                If you have any problems with the invitation, please contact
                <br />
                support@monitzion.com.
              </p>
            </span>
          </div>
        </Card>
      </div>
    </div >

  );
};

export default Invitation;
