import axios, { AxiosResponse, AxiosError } from 'axios'
import { startsWith, contains } from 'ramda'
import { apiUrl, apiVersion } from '../../configs/apiConfig'
import ApiRequest from './apiRequest'
import HttpMethod from './httpMethod'
import { fetchServiceToken } from '../../services/authService'
import { deref } from '@dbeining/react-atom'
import { appState } from '../../configs/state'

/*
Centralized code for all API requests. Extend axiosConfig if you need more advanced features.
*/

interface IHeaders {
  [header: string]: string
}

class RequestHeaders {
  private headers: IHeaders
  public constructor() {
    this.headers = {}
  }
  public async addAuthorizationHeader(token?: string | null) {
    
    if (!token) {
      token = await fetchServiceToken()
    }

    this.headers = {
      ...this.headers,
      Authorization: `Bearer ${token}`,
    }
    return this
  }
  public addTenant() {
    const { clientCompany } = deref(appState)
    const { role } = deref(appState)


    if (clientCompany) {
      this.headers = {
        ...this.headers,
        'X-ClientCompany-ID': clientCompany.id,
        'X-Role-ID': role ? role.id : '',
      }
    } else {
      console.warn('tenant unknown')
    }

    return this
  }

  public addJsonContentType() {
    this.headers = {
      ...this.headers,
      'Content-Type': 'application/json',
    }
    return this
  }

  public addNoCache() {
    this.headers = {
      ...this.headers,
      'Cache-Control': 'no-cache',
    }
    return this
  }

  public add() {
    return this.headers
  }
}

const formatResourceUrl = (resource: string) => {
  if (!startsWith('http', resource)) {
    resource = `${apiUrl}/${resource}`
  }

  if (!contains('api-version', resource)) {
    const apiVersionQueryParamDelimiter = resource.indexOf('?') > -1 ? '&' : '?'
    resource = `${resource}${apiVersionQueryParamDelimiter}api-version=${apiVersion}`
  }

  return resource
}

const addPayload = (options: any, method: any, payload: any) => {
  if (payload && method === HttpMethod.GET) {
    options.params = payload
  } else {
    options.data = payload
  }
}

const addResponseType = (options: any, responseType: any) => {
  if (!!responseType) {
    options.responseType = responseType
  }
}

export default async function apiRequest<D, T>({
  method,
  resource,
  token,
  payload,
  responseType,
}: ApiRequest<D>): Promise<AxiosResponse<T>> {
  if (!method) {
    method = HttpMethod.GET
  }

  const url = formatResourceUrl(resource)
  const headers = new RequestHeaders().addJsonContentType().addTenant()
  await headers.addAuthorizationHeader(token)

  const options: any = {
    method,
    url,
    headers: headers.add(),
  }

  addPayload(options, method, payload)
  addResponseType(options, responseType)

  return axios(options).then((response: AxiosResponse<T>) => {
    return response as any
  })
  // .catch(async (error: AxiosError) => {});
}
