import { Atom, swap } from '@dbeining/react-atom'
import { Role } from '../monitzionApi/role'
import { User, UserClientCompany } from '../monitzionApi/user'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { browserName, browserVersion } from 'react-device-detect'
import apiRequest from 'src/services/api/apiService'
import HttpMethod from '../services/api/httpMethod'
import { toast } from 'react-toastify'

export enum ProfileState {
  New,
  // RetrievingProfile,
  ProfileNotFound,
  RequiresConsent,
  LoggedIn,
}

export interface AppState {
  user: User | null
  clientCompany: UserClientCompany | null
  role: Role | null
  profileState: ProfileState
}

export interface AppActiveRecordName {
  activeGroupName: string | null
  activeCompanyName: string | null
  activeGroupId: string | null
  activeCompanyId: string | null
  activeApplicationId: string | null
  activeApplicationCode: string | null
}

export interface RoleCode {
  roleCode: string | null
}

const initialState: AppState = {
  user: null,
  clientCompany: null,
  role: null,
  profileState: ProfileState.New,
}
const initialStateName: AppActiveRecordName = {
  activeGroupName: '',
  activeCompanyName: '',
  activeGroupId: '',
  activeCompanyId: '',
  activeApplicationId: '',
  activeApplicationCode: '',
}
const initialStateRoleCode: RoleCode = {
  roleCode: '',
}

export const appState = Atom.of<AppState>({ ...initialState })
export const appActiveName = Atom.of<AppActiveRecordName>({
  ...initialStateName,
})
export const userViewCode = Atom.of<RoleCode>({ ...initialStateRoleCode })

export const resetState = () => {
  swap(appState, () => ({ ...initialState }))
}

// export const setClientCompanyName = (clientCompanyName: string) => {
//   debugger;
//   const array = appState;
//   // swap(appState, () => ({
//   //   ...appState,
//   //   clientCompany: {
//   //     id: '',
//   //     clientCompanyName: '',
//   //     clientCompanyCode: '',
//   //     roles: [],
//   //     createdAt: '',
//   //     clientGroup: []
//   //   },
//   // }))
// }

// export const setActiveGroup = (name:string,id:string) => {
//   swap(appActiveName, () => ({ activeGroupName:name,activeGroupId:id }))
// }
export const setActiveGroupandCompany = (
  grpname: string,
  grpid: string,
  compname: string,
  compid: string,
) => {
  swap(appActiveName, () => ({
    activeGroupName: grpname,
    activeGroupId: grpid,
    activeCompanyName: compname,
    activeCompanyId: compid,
  }))
}

export const setRoleCode = (code: string) => {
  swap(userViewCode, () => ({ roleCode: code }))
}

export const setActiveApplication = (
  applicationid: string,
  applicationcode: string,
) => {
  swap(appActiveName, () => ({
    activeApplicationId: applicationid,
    activeApplicationCode: applicationcode,
  }))
}

const cookies = new Cookies()

export interface userLoggedDetail {
  countryName: string
  stateName: string
  city: string
  iPAddress: string
  latitude: string
  longitude: string
  browserName: string
  browserVersion: string
}

export interface locationObj {
  Latitude: string
  Longitude: string
}

export const getCityStateLocation = (e) => {
  //alert("enter")
}

export const fetchUserLogged = async () => {
  let objUserLogged: userLoggedDetail = {
    countryName: '',
    stateName: '',
    city: '',
    iPAddress: '',
    latitude: '',
    longitude: '',
    browserName: '',
    browserVersion: '',
  }

  await axios
    .get('https://geolocation-db.com/json/')
    .then((data) => {
      const request = apiRequest<any, userLoggedDetail>({
        resource: 'api/UserLoginTracker',
        method: HttpMethod.POST,
        payload: {
          browserName: browserName,
          browserVersion: browserVersion,
          latitude: '',
          longitude: '',
          countryName: data.data.country_name,
          stateName: data.data.state,
          city: data.data.city,
          iPAddress: data.data.IPv4,
        },
      })
      request
        .then(() => {
          console.warn('objUserLogged')
          console.warn(objUserLogged)
        })
        .catch((err) => {
          console.warn('Encountered error saving user consent', err)
        })
    })
    .catch((error) => {
      const request = apiRequest<any, userLoggedDetail>({
        resource: 'api/UserLoginTracker',
        method: HttpMethod.POST,
        payload: {
          browserName: browserName,
          browserVersion: browserVersion,
          latitude: '',
          longitude: '',
          countryName: '',
          stateName: '',
          city: '',
          iPAddress: '',
        },
      })
      request
        .then(() => {
          console.warn('objUserLogged')
          console.warn(objUserLogged)
        })
        .catch((err) => {
          console.warn('Encountered error saving user consent', err)
        })
      console.warn('error..')
    })
}

export const setCurrentUser = (user: User) => {
  if (localStorage.getItem('userLogged') === 'false') {
    fetchUserLogged()
    localStorage.setItem('userLogged', 'true')
  }

  swap(appState, (state) => {
    let { clientCompany, role, profileState: loginProcess } = state

    if (!!state.user && !user) {
      // To prevent jumpy UI because of transient profile refresh retrieval problems
      return state
    }

    if (!user) {
      clientCompany = null
      role = null
      loginProcess = ProfileState.ProfileNotFound
    } else {
      // Reselect tenant/role
      clientCompany = !!clientCompany
        ? user.clientCompanies.find(
            (s) => s.id == (clientCompany ? clientCompany.id : ''),
          ) || null
        : null
      role = !!role
        ? clientCompany?.roles.id === (role ? role.id : '')
          ? clientCompany?.roles
          : null
        : null

      if (!clientCompany) {
        clientCompany =
          user.clientCompanies.length > 0 ? user.clientCompanies[0] : null
      }

      if (!role) {
        role =
          clientCompany && clientCompany.roles.id !== ''
            ? clientCompany.roles
            : null
      }

      loginProcess =
        user.privacyPolicyApproved && user.termsAndConditionsApproved
          ? ProfileState.LoggedIn
          : ProfileState.RequiresConsent
    }

    return { ...state, user, clientCompany, role, profileState: loginProcess }
  })
}

export const setCurrentTenant = (clientCompanyId: string) => {
  swap(appState, (state) => {
    const clientCompany = state.user
      ? state.user.clientCompanies.find((t) => t.id === clientCompanyId)
      : undefined
    if (clientCompany) {
      const role =
        clientCompany && clientCompany.roles.id !== ''
          ? clientCompany.roles
          : null
      return { ...state, clientCompany, role }
    } else {
      console.warn('Could not find ClientCompany', clientCompanyId)
      return state
    }
  })
}

export const setCurrentRole = (roleId: string) => {
  swap(appState, (state) => {
    const role = state.clientCompany
      ? state.clientCompany.roles.id === roleId
        ? state.clientCompany.roles
        : undefined
      : //? state.clientCompany.roles.find((r) => r.id === roleId)
        undefined
    if (role) {
      return { ...state, role }
    } else {
      console.warn('Could not find role', roleId)
      return state
    }
  })
}

export const setAzureEmail = (email: string) => {
  localStorage.setItem('AzureEmail', email)
}

export const toastSuccess = (text: string) => {
  toast.success(text, { position: toast.POSITION.BOTTOM_RIGHT })
}

export const toastWarning = (text: string) => {
  toast.warning(text, { position: toast.POSITION.BOTTOM_RIGHT })
}

export const toastError = (text: string) => {
  toast.error(text, { position: toast.POSITION.BOTTOM_RIGHT })
}
