import React, { Component, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { getIsLoggedIn } from './services/authService'
import Invitation from './pages/Invitation'
import Consent from './pages/Consent'
import './GlobalVariables/GlobalVariables'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./pages/Login'))
const isAuthenticated = getIsLoggedIn();

const App = () => {
  const localStorageKey = 'isLogout';

  useEffect(() => {
    const handleLogoutInOtherTabs = () => {
      window.addEventListener('storage', (event) => {
        if (event.key === 'logoutToken') {
          // Perform logout actions in this tab
          // (e.g., clear authentication tokens, user data, etc.)

          // Reload the page to log the user out
          
          window.location.reload();
        }
        if (event.key === 'loginToken') {
          
          //localStorage.removeItem('logoutToken');
          // Perform logout actions in this tab
          // (e.g., clear authentication tokens, user data, etc.)

          // Reload the page to log the user out
          window.location.reload();
        }
      });
    };

    handleLogoutInOtherTabs();
  }, []);

  // // useEffect to listen for changes in local storage
  // useEffect(() => {

  //   const handleStorageChange = (e) => {
  //     if (localStorage.getItem("isLogout") === 'yes' ||
  //       localStorage.getItem('isGoogleLogin') === 'yes') {
  //       if (localStorage.getItem('isGoogleLogin') === 'yes') {
  //         localStorage.removeItem('isGoogleLogin');
  //       }
  //       else {
  //         localStorage.removeItem("isLogout")
  //       }
  //       window.location.reload();
  //     }
  //   };

  //   // Add the event listener to monitor changes in local storage
  //   window.addEventListener('storage', handleStorageChange);

  //   return () => {
  //     // Clean up the event listener when the component unmounts
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, [localStorageKey]);

  return (
    <BrowserRouter>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>

          {/* {(!isAuthenticated || cookies.get('googletokenclient') === null) && <Route path="/invitation/*" element={<Invitation />}></Route>}
                {(isAuthenticated || cookies.get('googletokenclient') != null) && <Route path="/consent" element={<Consent/>} />}
                {(isAuthenticated || cookies.get('googletokenclient') != null) && <Route path="*" element={<DefaultLayout />}></Route>}
    
                {cookies.get('googletokenclient') != null && <Route path="*" element={<DefaultLayout />}></Route>} */}

          {(!isAuthenticated || localStorage.getItem('googletokenclient') === null) && <Route path="/invitation/*" element={<Invitation />}></Route>}
          {(isAuthenticated || localStorage.getItem('googletokenclient') != null) && <Route path="/consent" element={<Consent />} />}
          {(isAuthenticated || localStorage.getItem('googletokenclient') != null) && <Route path="*" element={<DefaultLayout />}></Route>}

          {localStorage.getItem('googletokenclient') != null && <Route path="*" element={<DefaultLayout />}></Route>}

          <Route path="*" element={<Login />}></Route>

        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App

// // Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// const Login = React.lazy(() => import('./pages/Login'))
// const isAuthenticated = getIsLoggedIn();
// const cookies = new Cookies();

// class App extends Component {

//   render(): JSX.Element {

//     return (
//       <BrowserRouter>
//         <Suspense fallback={<CSpinner color="primary" />}>
//           <Routes>

//             {/* {(!isAuthenticated || cookies.get('googletokenclient') === null) && <Route path="/invitation/*" element={<Invitation />}></Route>}
//             {(isAuthenticated || cookies.get('googletokenclient') != null) && <Route path="/consent" element={<Consent/>} />}
//             {(isAuthenticated || cookies.get('googletokenclient') != null) && <Route path="*" element={<DefaultLayout />}></Route>}

//             {cookies.get('googletokenclient') != null && <Route path="*" element={<DefaultLayout />}></Route>} */}

//             {(!isAuthenticated || localStorage.getItem('googletokenclient') === null) && <Route path="/invitation/*" element={<Invitation />}></Route>}
//             {(isAuthenticated || localStorage.getItem('googletokenclient') != null) && <Route path="/consent" element={<Consent />} />}
//             {(isAuthenticated || localStorage.getItem('googletokenclient') != null) && <Route path="*" element={<DefaultLayout />}></Route>}

//             {localStorage.getItem('googletokenclient') != null && <Route path="*" element={<DefaultLayout />}></Route>}

//             <Route path="*" element={<Login />}></Route>

//           </Routes>
//         </Suspense>
//       </BrowserRouter>
//     )
//   }
// }

// export default App
