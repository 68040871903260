export enum ServerEnumAppSupport {
    Localhost = "https://pa400t100000test.monitzionsolutions.com/ssc",
    Production = "https://monsm.monitzionsolutions.com/ssc",
    Test = "https://pa400t100000test.monitzionsolutions.com/ssc"
}

export enum ServerPostLogoutRedirectUri {
    Localhost = "http://localhost:1337/login",
    Production = "https://portal.monitzionsolutions.com/login",
    Test = "https://testportal.monitzionsolutions.com/login"
}

export enum ServerApiUri {
    Localhost = "http://localhost:10586",
    Production = "https://monitzion-weu-prod-apim.azure-api.net",
    Test = "https://monitzion-weu-test-apim.azure-api.net"
}

export enum ServerObj {
    Localhost = "Localhost",
    Production = "Production",
    Test = "Test"
}
 
export const ServerName : ServerObj = ServerObj.Test; //Set For Server

export const REACT_APP_SUPPORT : ServerEnumAppSupport = ServerEnumAppSupport[ServerName];
export const REACT_APP_LOGOUT : ServerPostLogoutRedirectUri = ServerPostLogoutRedirectUri[ServerName];
export const REACT_APP_API_URL : ServerApiUri = ServerApiUri[ServerName];